* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* || VARIABLES */

:root {
  /* FONTS */
  --FONT-FAMILY: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  --HEADER-FONT-SIZE: 1rem;

  /* COLORS */
  --BGCOLOR: #FFE8CD;
  --FONT-COLOR: #FFF;
  --BORDER-COLOR: #8D765A;
  --HEADER-BGCOLOR: #8D765A;

  /* BORDERS */
  --BORDERS: 6px solid var(--BORDER-COLOR);
  --BORDER-RADIUS: 15px;

}
img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* || UTILITY CLASSES */
.offscreen {
  position: absolute;
  left: -10000px;
}

.nowrap {
  white-space: nowrap;
}

.center {
  text-align: center;
}

.opacity_ {
  opacity: 0.5;
}

/* || GENERAL STYLES */

a:link {
  text-decoration: none;
}

a:visited, a:link{
  color: var(--FONT-COLOR);
}

.header__menu a:hover {
  background-color: #ad977c;
  border-radius: var(--BORDER-RADIUS);
}

.header__menu a:active {
  background-color: #7c6548;
}

body {
  background-color: var(--BGCOLOR);
}

header {
  background-color: var(--HEADER-BGCOLOR);
  font-family: var(--FONT-FAMILY);
  color: var(--FONT-COLOR);
}

.cellTags {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  
  color: white;
}

.dot {
  display: inline-block;
  background-color: #7c6548;
  margin-right: 2px;
  width: 5px;
  height: 5px;
  border-radius: var(--BORDER-RADIUS);
  align-content: center;
}


.header__logo {
  border-radius: var(--BORDER-RADIUS);
  margin-inline: 15px;
  
  padding-top: 3px;
}

.header__logo img{
  
  padding-left: 4px;
  padding-right: 4px;
}

.header__menu {
  display: flex;
  padding: 0.5rem;
  justify-content: space-evenly;
  text-align: center;
  
  font-size: 1.5rem;
}

.header__menu p{
  font-size: var(--HEADER-FONT-SIZE);
  padding: 0.5rem;

}

.header__subtext {
  font-size: 2rem;
}

.header__text {
  font-family:Arial, Helvetica, sans-serif ;
  font-size: 1rem;
}

.homescreen {
  
  color: var(--BORDER-COLOR);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;

}

.homescreen__title {

  text-align: center;
}

.homescreen__img{
  display: flex;
  width: min(100%, 350px);
  margin-top: 15%;
  border: var(--BORDERS);
  border-radius: var(--BORDER-RADIUS);
}

.logoLink{
  display: inline-block;
}


.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
}

.projectCell {
  display: flex;
  flex-flow: column nowrap;
  font-size: 1rem;
}
.projectCell__desc{
  margin-top: 0px;
  color: black;
}

.projectCell__authors{
  height: 55px;
  
}

.sidebar {
  position: fixed;
  top: 3.1rem;
  bottom: 0;
  left: 0;
  width: 350px;
  height: 100%;
  padding-block: 10px;
  overflow: auto;
  background-color: #333;
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  color: white;
}

.sidebar.open {
  transform: translateX(0);
}

.filterIcon{
  background-color:#333;
  border-radius: 15%;
  margin: 5px;
}

.filterIcon:hover {
  background-color: rgb(82, 82, 82);
  cursor: pointer;
}

.filterCloseIcon{
  background: linear-gradient(to right, #c5c5c5, #8f8f8f);
  border-radius: 15%;

}

.filterCloseIcon:hover {
  cursor: pointer;
}

.buttonHolder{
  display: flex;
  padding: 10px;
  gap: 10px;
}

.buttonHolder div{
  cursor: pointer;
}

.tagFilters{
  display: grid;
  padding-left: 10px;
}

.tagFilters h4{
  padding-block: 10px;
}

.tagFilters label{
  padding-inline: 20px;
}

.test {
  font-family: Montserrat;
  width: 60px;
  height: 20px;
  background: linear-gradient(to right, #c5c5c5, #8f8f8f);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: center;
}


.nodeContainer {
  background-color:#d6bb9c;
  width: min(100%, 350px);
  border: 3px solid var(--BORDER-COLOR);
  border-radius: var(--BORDER-RADIUS);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nodeContainer:hover {
  cursor: pointer;
  background-color: #ebd1b5;
  transform: scale(1.02);
}

.nodeContainer img{
  border: 5px solid white;
}

.nodeTitle {
  color:#63523e;
}

.tag {
  display: grid;
  align-items: center;
  grid-template-columns: 0.05fr 1fr;
  background-color: white;
  border-radius: 8px;
  padding-inline: 4px;
  margin-inline: 2px;
  margin-top: 4px;
  color: black;
}

.about{
  display: grid;
  justify-items: center;
}

.about__body{
  background-color: #8d765a6e;
  width: min(100%, 650px);
  padding: 4px;
}

.about__body p {
  margin: 10px;
}

.about__stairs{
  margin-top: 1rem;
}

.about__body figure {
  display: grid;
  justify-items: center;
}

.unreal{
  display: grid;
  justify-items: center;
}
.unreal__body {
  background-color:#8d765a6e;
  width: min(100%, 650px);
  padding: 4px;
  
}

.unreal__body p{
  margin: 10px;
}

.unreal__body figure{
  display: grid;
  justify-items: center;
}
.unreal__body li{
  margin-left: 2rem;
  
}

.static-text{
  padding-top: 4px;
}

.about__body video{
  display: block;
  margin: auto;
  width: min(100%, 650px);
  background-color: white;
}

.wrapper{
  display: inline-flex;
}

.wrapper .static-text {
  font-size: 1.5rem;
  font-weight: 400;
}

.wrapper .dynamic-text {
  margin-left: 15px;
  height: 2.2rem;
  line-height: 2.5rem;
  overflow: hidden;
}

.dynamic-text li{
  color: rgb(245, 111, 111);
  text-align: left;
  list-style: none;
  font-size: 1.5rem;
  font-weight: 500;

  position: relative;
  top: 0;
  animation: slide 6s steps(4) infinite;
}
  
  @keyframes slide {
    100% {
      top: -10rem;
    }
  }

.dynamic-text li span{
  position: relative;
}

.dynamic-text li span::after{
  content: '';
  position: absolute;
  padding-bottom: 5px;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--BGCOLOR);
  border-left: 2px solid rgb(245, 111, 111);
  animation: typing 1.5s steps(12) infinite;
}

@keyframes typing {
  100%{
    left: 100%;
    margin: 0 -35px 0 35px;
  }
}



@media screen and (min-width: 576px)  {
  .projects {
    justify-content: center;
    flex-flow: row wrap;
    padding: 1rem;
  }

  
  .projectCell__desc{
    height: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

/* || MEDIUM */
@media screen and (min-width: 768px) {
  .wrapper .static-text {
    font-size: 60px;
  }

  .wrapper .dynamic-text {
    height: 90px;
    line-height: 90px;
  }

  .dynamic-text li {
    font-size: 60px;
  }

  @keyframes slide {
    100%{
      top: -360px;
    }
  }

  .header__subtext {
    font-size: 3rem;
  }
  .header__text {
    font-size: 2rem;
  }

  .header__menu p{
    font-size: 2rem;
    padding: 0.5rem;
  }

  .sidebar{
    top: 4.2rem;
  }

}

/* || lARGE */
@media screen and (min-width: 992px) {
    .homescreen{
      display: grid;
      grid-template-columns: 1fr 0.5fr;
    }
}

/* || X lARGE */
@media screen and (min-width: 1200px) {
  .header__menu{
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.5rem;
    justify-content: right;
    text-align: center;
    gap: 15px;
  }

  .header__menu p{
    padding: 0.5rem;
    font-size: 1.5rem;
  }

  .sidebar {
    top: 3.75rem;
  }
}

/* || MOBILE DEVICE LANDSCAPE */
@media screen and (max-height: 425px) and (min-aspect-ratio: 7/4) {

}